
//https://somosconexaosocial.org/backnoticia
//http://localhost:3002

export const API_BASE_URL_NOTICIAS = 'https://somosconexaosocial.org/backnoticia';

// SERVIDOR IMAGENS
// 'https://somosconexaosocial.org'
// 'http://localhost:3002'

export const API_IMAGEM_URL = 'https://somosconexaosocial.org';
